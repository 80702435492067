import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import { Link } from 'gatsby'

import image404 from '../../static/img/404.png'

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container has-text-centered">
        <div className="columns">
          <div className="column is-three-fifths is-offset-one-fifth">
            <Heading className="heading">Ups! Não conseguimos encontrar esta página</Heading>
            <Image src={image404} alt="404 picture" />
            <p>Pode sempre voltar para a <HomeLink to="/">Página principal</HomeLink></p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage

const Heading = styled.p`
  max-width: 320px;
  margin: 0 auto;

  @media(min-width: 768px) {
    max-width: 430px;
  }
`

const Image = styled.img`
  margin: 15px auto -20px;
`

const HomeLink = styled(Link)`
  font-weight: 500;
  text-decoration: underline;
`
